
<script>
import { Bar } from "vue-chartjs";
export default {
  name: 'supplier-dashboard-view-bar-chart',
  extends: Bar,
  props: [
    'item',
    'funcs'
  ],
  methods: {
    render () {
      console.log('item xxxx ', this.item)
      this.renderChart({
        labels: this.item.labels,
        datasets: [
          {
            label: "จ.สมุทรปราการ",
            borderColor: "#CD6155",
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "white",
            backgroundColor: '#CD6155',
            data: this.item.data[0]
          }, {
            label: "เขตวัฒนา",
            borderColor: "#E74C3C",
            pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 1,
            backgroundColor: '#E74C3C',
            data: this.item.data[1]
          }, {
            label: "เขตมีนบุรี",
            borderColor: "#884EA0",
            pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 1,
            backgroundColor: '#884EA0',
            data: this.item.data[2]
          }, {
            label: "เขตบางนา",
            borderColor: "#7D3C98",
            pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 1,
            backgroundColor: '#7D3C98',
            data: this.item.data[3]
          }, {
            label: "เขตประเวศ",
            borderColor: "#2980B9",
            pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 1,
            backgroundColor: '#2980B9',
            data: this.item.data[4]
          }, {
            label: "เขตคลองสามวา",
            borderColor: "#3498DB",
            pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 1,
            backgroundColor: '#3498DB',
            data: this.item.data[5]
          }, {
            label: "เขตบึงกุ่ม",
            borderColor: "#F1C40F",
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "white",
            backgroundColor: '#F1C40F',
            data: this.item.data[6]
          }, {
            label: "เขตพระโขนง",
            borderColor: "#F39C12",
            pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 1,
            backgroundColor: '#F39C12',
            data: this.item.data[7]
          }, {
            label: "เขตสวนหลวง",
            borderColor: "#E67E22",
            pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 1,
            backgroundColor: '#E67E22',
            data: this.item.data[8]
          }, {
            label: "เขตราษฎร์บูรณะ",
            borderColor: "#D35400",
            pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 1,
            backgroundColor: '#D35400',
            data: this.item.data[9]
          }, {
            label: "เขตลาดกระบัง",
            borderColor: "#BDC3C7",
            pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 1,
            backgroundColor: '#BDC3C7',
            data: this.item.data[10]
          }, {
            label: "เขตบางคอแหลม",
            borderColor: "#95A5A6",
            pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 1,
            backgroundColor: '#95A5A6',
            data: this.item.data[11]
          // }, , {
          //   label: "เขตบางพลัด",
          //   borderColor: "#7F8C8D",
          //   pointBackgroundColor: "white",
          //   pointBorderColor: "white",
          //   borderWidth: 1,
          //   backgroundColor: '#7F8C8D',
          //   data: this.item.data[12]
          // }, {
          //   label: "เขตคลองสาน",
          //   borderColor: "#707B7C",
          //   pointBackgroundColor: "white",
          //   pointBorderColor: "white",
          //   borderWidth: 1,
          //   backgroundColor: '#707B7C',
          //   data: this.item.data[13]
          // }, {
          //   label: "เขตคลองเตย",
          //   borderColor: "#707B7C",
          //   pointBackgroundColor: "white",
          //   pointBorderColor: "white",
          //   borderWidth: 1,
          //   backgroundColor: '#707B7C',
          //   data: this.item.data[14]
          // }, {
          //   label: "เขตบางขุนเทียน",
          //   borderColor: "#34495E",
          //   pointBackgroundColor: "white",
          //   borderWidth: 1,
          //   pointBorderColor: "white",
          //   backgroundColor: '#34495E',
          //   data: this.item.data[15]
          // }, {
          //   label: "เขตหนองจอก",
          //   borderColor: "#E74C3C",
          //   pointBackgroundColor: "white",
          //   pointBorderColor: "white",
          //   borderWidth: 1,
          //   backgroundColor: '#E74C3C',
          //   data: this.item.data[16]
          // }, {
          //   label: "เขตสะพานสูง",
          //   borderColor: "#2C3E50",
          //   pointBackgroundColor: "white",
          //   pointBorderColor: "white",
          //   borderWidth: 1,
          //   backgroundColor: '#2C3E50',
          //   data: this.item.data[17]
          // }, {
          //   label: "เขตภาษีเจริญ",
          //   borderColor: "#A8D1DF",
          //   pointBackgroundColor: "white",
          //   pointBorderColor: "white",
          //   borderWidth: 1,
          //   backgroundColor: '#A8D1DF',
          //   data: this.item.data[18]
          // }, {
          //   label: "เขตบางกอกน้อย",
          //   borderColor: "#B2D5ED",
          //   pointBackgroundColor: "white",
          //   pointBorderColor: "white",
          //   borderWidth: 1,
          //   backgroundColor: '#B2D5ED',
          //   data: this.item.data[19]
          }
        ]
      }, {responsive: true, maintainAspectRatio: false})
    }
  },
  mounted () {
    console.log('item', this.item)
    this.render();
  },
  watch: {
    'item.labels' (newval, oldVal) {
      console.log('item.labels ', this.item.labels)
      this.render();
    }
  }
}
</script>