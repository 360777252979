export default {
  dashboard: {
    rows: [{
      date: '29/11/2021',
      suppiler_name: 'คุณหนุ่ย',
      samutPrakan: '8',
      khetWatthana: '5',
      khetMinBuri: '6',
      khetBangNa: '11',
      khetPrawet: '1',
      khetKhlongSamWa: '2',
      khetBuengKum: '3',
      khetPhraKhanong: '',
      khetSuanLuang: '',
      khetRatBurana: '',
      khetLatKrabang: '',
      khetBangKhoLaem: '',
      khetKhlongSan: '',
      khetKhlongToei: '',
      khetBangKhunThian: '',
      khetNongChok: '',
      khetSaphanSung: '2',
      khetPhasiCharoen: '',
      khetTalingChan: ''
    }, {
      date: '28/11/2021',
      suppiler_name: 'คุณหนุ่ย',
      samutPrakan: '8',
      khetWatthana: '5',
      khetMinBuri: '6',
      khetBangNa: '11',
      khetPrawet: '1',
      khetKhlongSamWa: '2',
      khetBuengKum: '3',
      khetPhraKhanong: '',
      khetSuanLuang: '',
      khetRatBurana: '',
      khetLatKrabang: '',
      khetBangKhoLaem: '',
      khetKhlongSan: '',
      khetKhlongToei: '',
      khetBangKhunThian: '',
      khetNongChok: '',
      khetSaphanSung: '2',
      khetPhasiCharoen: '',
      khetTalingChan: ''
    }, {
      date: '27/11/2021',
      suppiler_name: 'คุณหนุ่ย',
      samutPrakan: '8',
      khetWatthana: '5',
      khetMinBuri: '6',
      khetBangNa: '11',
      khetPrawet: '1',
      khetKhlongSamWa: '2',
      khetBuengKum: '3',
      khetPhraKhanong: '',
      khetSuanLuang: '',
      khetRatBurana: '',
      khetLatKrabang: '',
      khetBangKhoLaem: '',
      khetKhlongSan: '',
      khetKhlongToei: '',
      khetBangKhunThian: '',
      khetNongChok: '',
      khetSaphanSung: '2',
      khetPhasiCharoen: '',
      khetTalingChan: ''
    }, {
      date: '26/11/2021',
      suppiler_name: 'คุณหนุ่ย',
      samutPrakan: '8',
      khetWatthana: '5',
      khetMinBuri: '6',
      khetBangNa: '11',
      khetPrawet: '1',
      khetKhlongSamWa: '2',
      khetBuengKum: '3',
      khetPhraKhanong: '',
      khetSuanLuang: '',
      khetRatBurana: '',
      khetLatKrabang: '',
      khetBangKhoLaem: '',
      khetKhlongSan: '',
      khetKhlongToei: '',
      khetBangKhunThian: '',
      khetNongChok: '',
      khetSaphanSung: '2',
      khetPhasiCharoen: '',
      khetTalingChan: ''
    }, {
      date: '25/11/2021',
      suppiler_name: 'คุณหนุ่ย',
      samutPrakan: '8',
      khetWatthana: '5',
      khetMinBuri: '6',
      khetBangNa: '11',
      khetPrawet: '1',
      khetKhlongSamWa: '2',
      khetBuengKum: '3',
      khetPhraKhanong: '',
      khetSuanLuang: '',
      khetRatBurana: '',
      khetLatKrabang: '',
      khetBangKhoLaem: '',
      khetKhlongSan: '',
      khetKhlongToei: '',
      khetBangKhunThian: '',
      khetNongChok: '',
      khetSaphanSung: '2',
      khetPhasiCharoen: '',
      khetTalingChan: ''
    }],
    rowTotal: 1
  }
}
