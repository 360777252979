<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="card mb-4 custom-card">
                <div class="card-body">
                  <h3 class="mb-5">
                    ข้อมูลซัพพลายเออร์แต่ละเขตจัดส่ง
                  </h3>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="mb-4">
                        <label class="form-label">ซัพพลายเออร์</label>
                        <b-dropdown id="dropdown-2" text="ซัพพลายเออร์" class="dropdown_input_search mb-2">
                          <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                            <b-form-input placeholder="พิมพ์เพื่อค้นหา" @keyup="filterSuppliers" class="dropdown-form-control">
                            </b-form-input>
                            <ul style="list-style-type: none; padding-left: 0px;">
                              <li v-for="(v) in item.suppliers" :key="v.id" style="padding: .5rem 1rem; font-size: 0.875rem;">
                                <input type="checkbox" class="checkbox_search_input" :name="`checkbox_supplier${v.id}`"
                                  :id="`checkbox_supplier${v.id}`" v-model="v.selected" @change="selectSuppliers(v)"/>
                                <label class="form-check-label" v-bind:for="`checkbox_supplier${v.id}`">{{v.text}} ({{v.count}})</label>
                              </li>
                            </ul>
                          </b-dropdown-text>
                        </b-dropdown>
                        <!-- <Select2 v-model="item.suppliers"
                                  :options="suppliers"
                                  :settings="{multiple: true, allowClear: true, templateResult: formatSupplierCount}"
                                  placeholder="ซัพพลายเออร์"
                                  class="mb-4 select_2"/> -->
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-4">
                        <label class="form-label">เขตจัดส่ง</label>
                        <b-dropdown id="dropdown-2" text="เขตจัดส่ง" class="dropdown_input_search mb-2">
                          <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                            <b-form-input placeholder="พิมพ์เพื่อค้นหา" @keyup="filterLocations" class="dropdown-form-control">
                            </b-form-input>
                            <ul style="list-style-type: none; padding-left: 0px;">
                              <li v-for="(v) in item.delivery_locations" :key="v.id" style="padding: .5rem 1rem; font-size: 0.875rem;">
                                <input type="checkbox" class="checkbox_search_input" :name="`checkbox_location${v.id}`"
                                  :id="`checkbox_location${v.id}`" v-model="v.selected" @change="selectLocations(v)"/>
                                <label class="form-check-label" v-bind:for="`checkbox_location${v.id}`">{{v.text}} ({{v.count}})</label>
                              </li>
                            </ul>
                          </b-dropdown-text>
                        </b-dropdown>
                        <!-- <Select2 v-model="item.delivery_locations"
                                  :options="delivery_locations"
                                  :settings="{multiple: true, allowClear: true, templateResult: formatLocationCount}"
                                  placeholder="เขตจัดส่ง"
                                  class="mb-4 select_2"/> -->
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-4">
                        <label class="form-label">ตั้งแต่วันที่</label>
                        <date-picker  :format="'DD/MM/YYYY'"
                                      v-model="item.sale_start"
                                      class="w-100"
                                      placeholder="ตั้งแต่วันที่"
                                      :append-to-body="false"
                                      :popup-style="{left: 0}"
                                      :disabled-date="disabledSaleStartDate"></date-picker>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-4">
                        <label class="form-label">ถึง</label>
                        <date-picker  :format="'DD/MM/YYYY'"
                                      v-model="item.sale_end"
                                      class="w-100"
                                      placeholder="ถึง"
                                      :disabled-date="disabledSaleEndDate"
                                      :append-to-body="false"
                                      :popup-style="{left: 0}"></date-picker>
                      </div>
                    </div>
                    <div class="col-12 pt-5">
                      <bar-chart  :item="sales"
                                  chart-id="sales"
                                  :funcs="funcs"/>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive padding_top_20">
                        <table class="table align-middle table-bordered"
                                v-if="!indeterminate">
                          <thead>
                            <tr class="text-center">
                              <th v-for="(head, index) in label_header"
                                  :key="`header${index}`"
                                  v-if="head != 'ชื่อซัพพลายเออร์'">
                                <button :class="`c-plus-to-check ${indeterminate ? 'indeterminate' : ''}`"
                                        v-if="head == 'วันที่'"
                                        @click="clickIndeterminate()">
                                  <span class="c-plus-to-check__text"></span>
                                </button>
                                <span style="vertical-align: sub;">
                                  {{head}}
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(data, index) in rows"
                                :key="`body${index}`">
                              <td v-for="(prop, index) in prop_data"
                                :key="`body_td${index}`"
                                v-if="prop != 'suppiler_name'">
                                {{data[prop] ? data[prop] : '-'}}
                              </td>
                            </tr>
                            <tr v-if="rows.length === 0" class="text-center">
                              <td colspan="6">ไม่มีข้อมูล</td>
                            </tr>
                          </tbody>
                        </table>
                        <table  class="table align-middle table-bordered"
                                v-if="indeterminate">
                          <thead>
                            <tr class="text-center">
                              <th v-for="(head, index) in label_header"
                                  :key="`header${index}`">
                                <button :class="`c-plus-to-check ${indeterminate ? 'indeterminate' : ''}`"
                                        v-if="head == 'วันที่'"
                                        @click="clickIndeterminate()">
                                  <span class="c-plus-to-check__text"></span>
                                </button>
                                <span style="vertical-align: sub;">
                                  {{head}}
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(data, index) in rows"
                                :key="`body${index}`">
                              <td v-for="(prop, index) in prop_data"
                                :key="`body_td${index}`">
                                {{data[prop] ? data[prop] : '-'}}
                              </td>
                            </tr>
                            <tr v-if="rows.length === 0" class="text-center">
                              <td colspan="6">ไม่มีข้อมูล</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as chartConfigs from '@/components/Charts/config';
import * as scripts from '../../util/scripts';
import barChart from './view/barChart';
import mock from './mock/supplier_dashboard';
export default {
  name: 'supplier-dashboard-index',
  data () {
    return {
      sales: {
        labels: ['คุณหนุ่ย'],
        data: [
          [8, 0, 0, 25, 10],
          [4, 0, 0, 17, 2],
          [1, 1, 1, 55, 40],
          [0, 0, 0, 0, 0],
          [0, 1, 0, 8, 2],
          [0, 0, 0, 11, 5],
          [30, 15, 11, 5, 22],
          [21, 53, 1, 55, 40],
          [15, 8, 0, 0, 100],
          [0, 100, 2, 4, 5],
          [1, 34, 8, 0, 0],
          [22, 43, 40, 15, 8]
        ]
      },
      item: {
        suppliers: [{
          id: 1,
          text: 'คุณหนุ่ย',
          count: 812,
          selected: true
        }, {
          id: 2,
          text: 'คุณแอน',
          count: 508,
          selected: false
        }, {
          id: 3,
          text: 'คุณลูกปลา',
          count: 500,
          selected: false
        }, {
          id: 4,
          text: 'คุณเบียร์',
          count: 332,
          selected: false
        }, {
          id: 5,
          text: 'ร้านคุณนก รัชดา',
          count: 278,
          selected: false
        }],
        delivery_locations: [{
          id: 1,
          text: 'จ.สมุทรปราการ',
          count: 179,
          selected: true
        }, {
          id: 2,
          text: 'เขตวัฒนา',
          count: 167,
          selected: true
        }, {
          id: 3,
          text: 'เขตมีนบุรี',
          count: 55,
          selected: true
        }, {
          id: 4,
          text: 'เขตบางนา',
          count: 43,
          selected: true
        }, {
          id: 5,
          text: 'เขตประเวศ',
          count: 39,
          selected: true
        }],
        sale_start: '',
        sale_end: '',
      },
      funcs: scripts.funcs,
      label_header: [
        'วันที่',
        'ชื่อซัพพลายเออร์',
        'จ.สมุทรปราการ',
        'เขตวัฒนา',
        'เขตมีนบุรี',
        'เขตบางนา',
        'เขตประเวศ',
        'เขตคลองสามวา',
        'เขตบึงกุ่ม',
        'เขตพระโขนง',
        'เขตสวนหลวง',
        'เขตราษฎร์บูรณะ',
        'เขตลาดกระบัง',
        'เขตบางคอแหลม',
        'เขตคลองสาน',
        'เขตบางขุนเทียน',
        'เขตหนองจอก',
        'เขตสะพานสูง',
        'เขตบางพลัด',
        'เขตภาษีเจริญ',
        'เขตตลิ่งชัน'
      ],
      rows: [],
      prop_data: [
        'date',
        'suppiler_name',
        'samutPrakan',
        'khetWatthana',
        'khetMinBuri',
        'khetBangNa',
        'khetPrawet',
        'khetKhlongSamWa',
        'khetBuengKum',
        'khetPhraKhanong',
        'khetSuanLuang',
        'khetRatBurana',
        'khetLatKrabang',
        'khetBangKhoLaem',
        'khetKhlongSan',
        'khetKhlongToei',
        'khetBangKhunThian',
        'khetNongChok',
        'khetSaphanSung',
        'khetPhasiCharoen',
        'khetTalingChan',
      ],
      mock: mock,
      indeterminate: false
    }
  },
  methods: {
    disabledSaleStartDate(date) {
      if(this.item.sale_end){
        return date > this.item.sale_end;
      }
      return false;
    },
    disabledSaleEndDate(date){
      if(this.item.sale_start){
        return date < this.item.sale_start;
      }
      return false;
    },
    formatSupplierCount (data) {
      if (!data.id) {
        return data.text;
      }
      return $(`<span>${data.text} <span class="badge badge-pill badge-success">${data.count}</span></span>`);
    },
    formatLocationCount (data) {
      if (!data.id) {
        return data.text;
      }
      return $(`<span style="display: flex; align-items: center;">
        <input type="checkbox" class="form-control mr-2" style="width: 12px;">
        ${data.text} 
        <span class="badge badge-pill badge-success m-auto">
          ${data.count}
        </span>
      </span>`);
    },
    filterLocations(e){
      const name = e.target.value;
      const locations = this.delivery_locations.filter((v)=>v.text.toLowerCase().indexOf(name)>-1);
      this.item.delivery_locations = locations;
    },
    selectLocations(item){
      this.searchHandler();
    },
    searchHandler(){
      this.getListItems();
    },
    async getListItems () {
      let filters = [];
      this.item.delivery_locations.map((v)=>{
        if(v.selected){
          filters.push(v.id);
        }
      });
      filters = filters.join(",");
      
      let filtersSuppliers = [];
      this.item.suppliers.map((v)=>{
        if(v.selected){
          filtersSuppliers.push(v.id);
        }
      });
      filtersSuppliers = filtersSuppliers.join(",");

      let result = [];
      let suppliers = this.item.suppliers.filter((row) => {
        if (row.selected) {
          return row.text;
        }
      });
      let delivery_locations = this.item.delivery_locations.filter((row) => {
        if (row.selected) {
          return row.text;
        }
      });
      result = suppliers.map(row => row.text);
      this.sales.labels = result;
      console.log('labels ', this.sales.labels)
    },
    filterSuppliers (e) {
      const name = e.target.value;
      const suppliers = this.suppliers.filter((v)=>v.text.toLowerCase().indexOf(name)>-1);
      this.item.suppliers = suppliers;
    },
    selectSuppliers(item){
      this.searchHandler();
    },
    getDashboard () {
      this.rows = this.mock.dashboard.rows;
    },
    clickIndeterminate () {
      this.indeterminate = !this.indeterminate;
    }
  },
  components: {
    barChart
  },
  created () {
    this.getDashboard();
  }
}
</script>
<style lang="scss" scoped>
  .dropdown-menu {
    min-width: 15rem !important;
  }
  .dropdown {
    display: flex;
  }
  td, th {
    text-align: center;
  }
  // *, *:before, *:after {
  //   box-sizing: border-box;
  // }
  .c-plus-to-check {
    position: relative;
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    background-color: #DDDDDD;
    font-size: 5px;
    border-radius: 3px;
    border: 1px solid #DDDDDD;
    
    &:before, &:after {
      background-color: #000000;
      content: "";
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0.375em;
      right: 0.375em;
      height: 0.250em;
      transition: transform 300ms cubic-bezier(.75,-0.6,.14,1.59) 150ms;
      will-change: transform background-color;
    }

    &:before {
      transform: translate(0, 0) rotate(-90deg) scale(1, 1);
    }

    &:after {
      transform: translate(0, 0) rotate(180deg) scale(1, 1);
    }

    &__text {
      position: absolute;
      top: -3125rem;
      left: -3125rem;
      font-size: 0;
      height: 0;
      width: 0;
      overflow: hidden;
      visibility: hidden;
    }
    
    // // &:hover, &:focus, &:active { // demo without JS
    // &[data-state='active'] {
    //   &:before, &:after {
    //     background-color: #89B937;
    //     transition-duration: 150ms;
    //     transition-timing-function: ease-out;
    //     transition-delay: 0s;
    //   }
      
    //   &:before {
    //     transform: translate(calc(25% - .175em), -.175em) rotate(-45deg) scale(1, 1);
    //   }


    //   &:after {
    //     transform: translate(-25%, .175em) rotate(45deg) scale(.43, 1);
    //   }
    // }
  }
  button:focus {
    outline: none;
  }
</style>
